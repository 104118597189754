const initialState = {
    data : [],
    totalPages : 1
}

const logsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_DATA':
            return {
                ...state,
                data : action.data,
                totalPages : action.totalPages
            }
        default:
            return {...state}
    }
}

export default logsReducer