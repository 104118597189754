// MyContext.js
import { createContext, useContext, useState } from 'react';

const MyContext = createContext();

export const MyContextProvider = ({ children }) => {
  const [myValue, setMyValue] = useState(50);

  const updateValue = (newValue) => {
    setMyValue(parseInt(newValue));
    
    localStorage.setItem('pageSize', newValue)
  };

  return (
    <MyContext.Provider value={{ myValue, updateValue }}>
      {children}
    </MyContext.Provider>
  );
};

export const useMyContext = () => {
  return useContext(MyContext);
};
